import { Component, Input } from '@angular/core';
import { IMcbAusgewaehlteProjekte } from 'src/rest-interfaces/IModularContent.field';
import { TranslateSvc } from 'src/app/i18n/translation.i18n';
import { LocaleService } from 'src/app/localization/locale.service';

@Component({
  selector: 'app-ausgewaehlte-projekte',
  templateUrl: './ausgewaehlte-projekte.component.html'
})
export class AusgewaehlteProjekteComponent {
  @Input() public content: IMcbAusgewaehlteProjekte;	
  public projekteLink = '';
  constructor(
    public translateSvc: TranslateSvc,
    public localeSvc: LocaleService
  ) {
    this.projekteLink = this.localeSvc.LocalePathPre + '/projekte'
  }

  public getGridCssCellClasses(i: number): string {
    const rowNoL = Math.floor(i / 2) + 1;
    const colNoL = i % 2;
    const rowNoXL = Math.floor(i / 3) + 1;
    const colNoXL = i % 3;

    const colStartL = Math.floor(colNoL * 12) + 2;
    const colEndL = colStartL + 10;

    const colStartXL = Math.floor(colNoXL * 8) + 2;
    const colEndXL = colStartXL + 6;

    const gridClasses = [
      'row-s-' + (i + 1).toString(),
      'col-s-3-22',
      'row-l-' + rowNoL,
      'col-l-' + colStartL.toString() + '-' + colEndL.toString(),
      'row-xl-' + rowNoXL,
      'col-xl-' + colStartXL.toString() + '-' + colEndXL.toString(),
    ];

    return gridClasses.join(' ');
  }
}
