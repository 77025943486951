import { Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { Breadcrumb } from '../breadcrumbs/Breadcrumb';
import { filter } from 'rxjs/operators';
import { LocaleService } from '../localization/locale.service';
import { UrlHelper } from '../helpers/url.helper';
import { ELocale } from '../localization/ELocale';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/api/api.service';


@Injectable()
export class SucheService {

  public error: string = '';
  public searchTerm: string = '';
  public searchResults: any[] = [];
  public loading: boolean = false;

  constructor(
    private API: ApiService,
  ) {

  }

  private sanitizeSearchTerm(searchTerm: string): string {
    return searchTerm.replace(/[^a-zA-Z0-9\s-üäöÜÄÖß]/g, '');
  }

  suchen() {
    this.loading = true;
    this.error = '';
    console.log(this.searchTerm);
    const sanitizedSearchTerm = this.sanitizeSearchTerm(this.searchTerm);
    const formatedSearchTerm = sanitizedSearchTerm.replace(/ /g, '-');

    this.API.get(
      '/suche/' + formatedSearchTerm
    ).subscribe(
      (response: any) => {
        console.log(response);

        this.searchResults = response;
        this.error = '';
      },
      err => { 
        console.error( err );
      },
      () => {
        this.loading = false;
      }
    );
  }

}
