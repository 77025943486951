import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BoFormsModule } from './bo-forms/bo-forms.module';
import { FormsModule } from '@angular/forms';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AddThisModule } from './addthis/addthis.module';
import { FacebookModule } from 'ngx-facebook';

import { ApiService } from './api/api.service';
import { EntryService } from './services/entry.service';
import { LocaleService } from './localization/locale.service';
import { HyphenatorService } from './hyphenator/hyphenator.service';
import { ScrollService } from './services/scroll.service';
import { NavigationService } from './services/navigation.service';
import { FooterService } from './services/footer.service';
import { FilterPipe } from './services/filter.pipe';
import { BreadcrumbsService } from './services/breadcrumbs.service';
import { ImageTextBlockComponent } from './modular-content/image-text-block/image-text-block.component';
import { SliderConfigService } from './services/slider-config.service';
import { JournalService } from './services/journal.service';
import { ScrollPositionService } from './services/scroll-position/scroll-position.service';
import { MedienService } from './services/medien.service';
import { LightboxService } from './services/lightbox.service';
import { LoginService } from './services/login.service';
import { SucheService } from './services/suche.service';

import { SetLocaleDirective } from './directives/set-locale.directive';

import { AppComponent } from './app.component';
import { HomeComponent } from './page-components/home/home.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ModularContentComponent } from './modular-content/modular-content.component';
import { JournalPreviewComponent } from './modular-content/journal-preview/journal-preview.component';
import { LinkBannerComponent } from './modular-content/link-banner/link-banner.component';
import { ProjectsPreviewComponent } from './modular-content/projects-preview/projects-preview.component';
import { ContactBannerComponent } from './modular-content/contact-banner/contact-banner.component';
import { OffersPreviewComponent } from './modular-content/offers-preview/offers-preview.component';
import { HyphenatePipe } from './hyphenator/hyphenate.pipe';
import { ThreeColTextTeaserComponent } from './modular-content/three-col-text-teaser/three-col-text-teaser.component';
import { TeamComponent } from './page-components/ueber-uns/team/team.component';
import { TeamMemberComponent } from './page-components/ueber-uns/team/team-member/team-member.component';
import { UeberUnsComponent } from './page-components/ueber-uns/ueber-uns.component';
import { AngeboteComponent } from './page-components/angebote/angebote.component';
import { AngeboteItemComponent } from './page-components/angebote/angebote-item.component';
import { ProjekteComponent } from './page-components/projekte/projekte.component';
import { ProjekteItemComponent } from './page-components/projekte/projekte-item.component';
import { DefaultPageComponent } from './page-components/default-page/default-page.component';
import { KontaktComponent } from './page-components/kontakt/kontakt.component';
import { LoginComponent } from './page-components/login/login.component';
import { SucheComponent } from './page-components/suche/suche.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { JournalComponent } from './journal/journal.component';
import { ModularContentJournalComponent } from './journal/modular-content-journal/modular-content-journal.component';
import { JournalItemComponent } from './journal/journal-item/journal-item.component';
import { JournalTextBlockComponent } from './journal/modular-content-journal/journal-text-block/journal-text-block.component';
import { JournalBildBlockComponent } from './journal/modular-content-journal/journal-bild-block/journal-bild-block.component';
import { JournalVideoBlockComponent } from './journal/modular-content-journal/journal-video-block/journal-video-block.component';
import { JournalClaimComponent } from './journal/modular-content-journal/journal-claim/journal-claim.component';
import { JournalKontaktComponent } from './journal/modular-content-journal/journal-kontakt/journal-kontakt.component';
import { TranslatePipe, TranslateSvc } from './i18n/translation.i18n';
import { FreitextBlockComponent } from './modular-content/freitext-block/freitext-block.component';
import { NavIconComponent } from './header/nav-icon/nav-icon.component';
import { VideoComponent } from './video/video.component';
import { BreadcrumbsJournalComponent } from './breadcrumbs/breadcrumbs-journal.component';
import { GeneralSubsiteComponent } from './page-components/general-subsite/general-subsite.component';
import { SocialMediaComponent } from './journal/social-media/social-media.component';
import { PlantATreeComponent } from './plant-a-tree/plant-a-tree.component';
import { IssuuComponent } from './issuu/issuu.component';
import { WettbewerbsgruppeComponent } from './modular-content/wettbewerbsgruppe/wettbewerbsgruppe.component';
import { AusgewaehlteProjekteComponent } from './modular-content/ausgewaehlte-projekte/ausgewaehlte-projekte.component';
import { SidebarJournalPreview } from './page-components/home/sidebar-journal-preview/sidebar-journal-preview.component';
import { SidebarNewsPreview } from './page-components/home/sidebar-news/sidebar-news.component';
import { ShortenPipe } from './pipes/shorten.pipe';
import { HdhComponent } from './page-components/hdh/hdh.component';
import { JournalHdhPreviewComponent } from './modular-content/journal-hdh-preview/journal-hdh-preview.component';
import { WebcamComponent } from './modular-content/webcam/webcam.component';
import { JournalWebcamComponent } from './journal/modular-content-journal/journal-webcam/journal-webcam.component';
import { SlideshowComponent } from './page-components/slideshow/slideshow.component';
import { ZeitstrahlComponent } from './modular-content/zeitstrahl/zeitstrahl.component';
import { BenefitsComponent } from './modular-content/benefits/benefits.component';
import { BildSlideBlockComponent } from './modular-content/bild-slide-block/bild-slide-block.component';
import { MedienComponent } from './journal/medien/medien.component';
import { ProjektMedienComponent } from './page-components/projekte/medien/projekt-medien.component';
import { LightboxComponent } from './lightbox/lightbox.component';


@NgModule( {
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    AddThisModule,
    BoFormsModule,
    FormsModule,
    ScrollToModule.forRoot(),
    FacebookModule.forRoot()
  ],
  providers: [
    LocaleService,
    TranslateSvc,
    BreadcrumbsService,
    ApiService,
    EntryService,
    JournalService,
    NavigationService,
    FooterService,
    HyphenatorService,
    ScrollService,
    SliderConfigService,
    ScrollPositionService,
    MedienService,
    LightboxService,
    LoginService,
    SucheService,
  ],
  declarations: [
    AppComponent,
    SetLocaleDirective,
    TranslatePipe,
    NavigationComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    BreadcrumbsComponent,
    BreadcrumbsJournalComponent,
    ModularContentComponent,
    JournalPreviewComponent,
    LinkBannerComponent,
    ProjectsPreviewComponent,
    ContactBannerComponent,
    OffersPreviewComponent,
    HyphenatePipe,
    ThreeColTextTeaserComponent,
    UeberUnsComponent,
    HdhComponent,
    JournalHdhPreviewComponent,
    TeamComponent,
    TeamMemberComponent,
    AngeboteComponent,
    AngeboteItemComponent,
    ProjekteComponent,
    ProjekteItemComponent,
    DefaultPageComponent,
    KontaktComponent,
    LoginComponent,
    SucheComponent,
    FilterPipe,
    ImageTextBlockComponent,
    FreitextBlockComponent,
    JournalComponent,
    ModularContentJournalComponent,
    JournalItemComponent,
    JournalTextBlockComponent,
    JournalBildBlockComponent,
    JournalVideoBlockComponent,
    JournalClaimComponent,
    JournalKontaktComponent,
    NavIconComponent,
    VideoComponent,
    GeneralSubsiteComponent,
    SocialMediaComponent,
    IssuuComponent,
    PlantATreeComponent,
    WettbewerbsgruppeComponent,
    WebcamComponent,
    JournalWebcamComponent,
    SidebarJournalPreview,
    SidebarNewsPreview,
    ShortenPipe,
    SlideshowComponent,
    ZeitstrahlComponent,
    BenefitsComponent,
    BildSlideBlockComponent,
    MedienComponent,
    ProjektMedienComponent,
    LightboxComponent,
    AusgewaehlteProjekteComponent
  ],
  bootstrap: [ AppComponent ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
} )
export class AppModule { }
