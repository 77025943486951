import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { generalAnimations } from 'src/app/_animations/general.animations';
import {NgForm} from '@angular/forms';
import { ApiService } from 'src/app/api/api.service';
import { SucheService } from 'src/app/services/suche.service';



@Component( {
  selector: 'app-suche',
  templateUrl: './suche.component.html',
  animations: [ generalAnimations ]
} )
export class SucheComponent {

  constructor(
    public sucheSvc: SucheService,
    private router: Router,
  ) {
  }


  public onSearch(event) {
    event.preventDefault();
    if(!this.sucheSvc.searchTerm || this.sucheSvc.searchTerm.trim() === '') {
      this.sucheSvc.error = 'Bitte geben Sie einen Suchbegriff ein.';
      return;
    }

    this.sucheSvc.suchen();
  }

}
