import { IMatrixField } from './IMatrixField.field';
import { IEntries } from './IEntry';
import { ITable } from './ITable.field';
import { IEntryProjekteItemPreview } from './projekte/IEntryProjekte';
import { IAssets } from './IAsset';
import { IEntryTeamMemberSimpleContactBanner } from './ueber-uns/IEntryTeam';
import { IEntryJournalPreview, IEntryJournalGrid } from './IEntryJournal';
import { EContentBlockOrientation } from './EContentBlockOrientation';

export type IModularContent = Array<
  IMcbLinkBanner
  | IMcb3SPaltenTeasersTabelle
  | IMcbJournalEintraegePreview
  | IMcbProjektePreview
  | IMcbAngebotePreview
  | IMcbAusgewaehlteProjekte
  | IMcbKontakteBanner
  | IMcbFreitextBlock
  | IMcbIssuuBlock
  | IMcbTreeBlock
  | IMcbWettbewerbsgruppe
  | IMcbJournalHdhPreview
  | IMcbZeitstrahl
  | IMcbBenefits
  | IMcbBildBlock
>;

export enum EModularContentBlock {
  linkBanner = 'linkBanner',
  threeColTextTeaser = 'threeColTextTeaser',
  journalPreview = 'journalPreview',
  projectsPreview = 'projectsPreview',
  offersPreview = 'offersPreview',
  contactBanner = 'contactBanner',
  imageTextBlock = 'imageTextBlock',
  freitextBlock = 'freitextBlock',
  issuuBlock = 'issuuBlock',
  treeBlock = 'treeBlock',
  wettbewerbsgruppe = 'wettbewerbsgruppe',
  ausgewaehlteProjekte = 'ausgewaehlteProjekte',
  journalHdhPreview = 'journalHdhPreview',
  webcam = 'webcam',
  zeitstrahl = 'zeitstrahl',
  benefits = 'benefits',
  bildSlideBlock = 'bildSlideBlock'
}

export interface IModularContentBlock extends IMatrixField {
  blocktype: EModularContentBlock;

  hintergrundfarbe: string;
}

// ----------------------------------------
// Matrix Blocks

export interface IMcbLinkBanner extends IModularContentBlock {
  blocktype: EModularContentBlock.linkBanner;
  text: string;
  eintragLink: IEntries;
  linkText: string;
}
export interface IMcbWebcam extends IModularContentBlock {
  blocktype: EModularContentBlock.webcam;
  titel: string;
  webcamUrl: string;
}
export interface IMcb3SPaltenTeasersTabelle extends IModularContentBlock {
  blocktype: EModularContentBlock.threeColTextTeaser;
  eintraege: Array<IMcbEintraegeTable>;
}
export interface IMcbJournalEintraegePreview extends IModularContentBlock {
  blocktype: EModularContentBlock.journalPreview;
  titel: string;
  eintraege: Array<IEntryJournalPreview>;
  additionalClasses?: Array<string>; // only used in HomeController
}
export interface IMcbProjektePreview extends IModularContentBlock {
  blocktype: EModularContentBlock.projectsPreview;
  titel: string;
  projects: Array<IEntryProjekteItemPreview>;
}
export interface IMcbAngebotePreview extends IModularContentBlock {
  blocktype: EModularContentBlock.offersPreview;
  ueberschrift?: string;
  titel: string;
  text: string;
  bereiche: Array<IMcbAngebotBereich>;
}
export interface IMcbKontakteBanner extends IModularContentBlock {
  blocktype: EModularContentBlock.contactBanner;
  text?: string;
  eintragDesKontaktesWelcherVerlinktWerdenSo: Array<IEntryTeamMemberSimpleContactBanner>;
}
export interface IMcbImageTextBlock extends IModularContentBlock {
  blocktype: EModularContentBlock.imageTextBlock;
  titel: string;
  text: string;
  images: IAssets;
  imageRight: boolean;
  linkText: string;
  target: IEntries;
}
export interface IMcbFreitextBlock extends IModularContentBlock {
  blocktype: EModularContentBlock.freitextBlock;
  freitext: string;
}
export interface IMcbIssuuBlock extends IModularContentBlock {
  blocktype: EModularContentBlock.issuuBlock;
  embedUrl: string;
  quelleBeschreibung: string;
}
export interface IMcbTreeBlock extends IModularContentBlock {
  blocktype: EModularContentBlock.treeBlock;
  baumzaehlerAnzeigen: boolean;
}
export interface IMcbWettbewerbsgruppe extends IModularContentBlock {
  blocktype: EModularContentBlock.wettbewerbsgruppe;
  titel: string;
  projekte: IEntries;
  hasMainHeading: boolean;
}
export interface IMcbAusgewaehlteProjekte extends IModularContentBlock {
  blocktype: EModularContentBlock.ausgewaehlteProjekte;
  titel: string;
  projekte: IEntries;
  hasMainHeading: boolean;
}
export interface IMcbJournalHdhPreview extends IModularContentBlock {
  blocktype: EModularContentBlock.journalHdhPreview;
  titel: string;
  hdhEntries: IEntryJournalGrid;
}
export interface IMcbZeitstrahl extends IModularContentBlock {
  blocktype: EModularContentBlock.zeitstrahl;
  ereignisse: Array<any> 
}

export interface IMcbBenefits extends IModularContentBlock {
  blocktype: EModularContentBlock.benefits;
  benefits: Array<any> 
}

export interface IMcbBildBlock extends IModularContentBlock {
  blocktype: EModularContentBlock.bildSlideBlock;
  bild: IAssets;
  position: EContentBlockOrientation;
  bildBeschriftung: any;
  sliderConfig: { [ key: string ]: any; };
}

// ----------------------------------------
// Addidional helper interfaces

export interface IMcbEintraegeTable extends ITable {
  titel: string;
  text: string;
  uri: string;
}
interface IMcbAngebotBereich extends ITable {
  titel: string;
  bild: string;
  text: string;
  link: string;
}
